'use client'
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import React, { useState, useCallback, useMemo } from 'react'
import QuickViewModal from './QuickViewModal'
import AddToWishList from '../wishlist/AddToWishList';
import Image from 'next/image';
const ProductCard = ({ product, style, iconStyle, textSize, quickViewWidth, quickViewHeight, isSwiper }) => {
    const { product_images, item_slug, item_name, max_retail_price, list_price, _id, variation_group_id, country, brand, awards, parent_category, sub_category, child_category, inventory } = product;
    const images = useMemo(() => {
        if (!product_images) return [];
        return Object.values(product_images).filter(image => image);
    }, [product_images]);
    const [bgImg, setBgImg] = useState(images[Math.floor(Math.random() * images.length + 1)] || product_images?.main_img)



    const award = awards ? awards : []
    const router = useRouter()
    const [isHover, setIsHover] = useState(false)
    const [isClicked, setIsClicked] = useState(false)
    const handleClick = useCallback(() => {
        setIsClicked(false)
    }, [])


    const handleMouseEnter = useCallback(() => {
        setBgImg(images[Math.floor(Math.random() * images.length + 1)] || product_images?.main_img)
        setIsHover(true)

    }, [])
    const handleMouseLeave = useCallback(() => {
        setBgImg(images[Math.floor(Math.random() * images.length + 1)] || product_images?.main_img)
        setIsHover(false)

    }, [])
    const redirectLink = `/product/${item_slug}/buy/${_id}`
    const brandPageLink = `/brand/${brand?.brand_name?.toLowerCase()}/home?brand_id=${brand?._id}`
    return (
        <div
            onMouseEnter={() => handleMouseEnter()}
            onMouseLeave={() => handleMouseLeave()}
            className={`${style} relative  rounded-lg bg-cover  bg-center  bg-no-repeat overflow-hidden `}
        // style={{
        //     backgroundImage: `url(${bgImg})`,

        // }}
        >
            <img loading='lazy' src={bgImg} alt={item_name}
                width={500}
                height={500}
                className='w-full h-full object-cover ' />
            <div
                onClick={() => router.push(redirectLink)}
                className={`${isHover ? 'block' : 'hidden'}  absolute inset-0 bg-black opacity-30 cursor-pointer`}
                style={{
                    transition: 'background-color 0.5s ease-in-out 0.5s' // Add the transition with a 0.2s delay
                }}
            />
            <div className='absolute -right-1 top-0 cursor-pointer'>
                <AddToWishList productId={_id} style={'p-1 bg-white rounded-full'} />
            </div>

            <div className={` ${isHover ? 'flex flex-col duration-500' : 'hidden'}   absolute inset-x-2 bottom-2 z-10 `}>
                {Array.isArray(awards) ?
                    <div className={`w-fit rounded-tl-lg rounded-tr-lg  flex flex-row gap-2 bg-[#fff] px-2 py-1 awards-shape ${awards?.length ? 'block' : 'hidden'} `}
                    >
                        {award?.slice(0, 3).map((award) => (
                            <img loading='lazy' key={award._id} src={award.image} className={`${iconStyle} rounded-full`} />
                        ))}


                        {
                            award?.length ?
                                <div className={`w-4 h-4 `} />
                                : null
                        }

                    </div>
                    : null
                }
                <div className='flex flex-col shadow bg-[#fff] rounded-b-lg rounded-tr-lg  px-2 py-1'>
                    <div className='relative flex flex-row justify-between items-center'>
                        <div className='flex flex-col'>
                            <div className='flex gap-2 items-center'>
                                <Link target="_blank" href={brandPageLink}>{brand?.brand_name}</Link>
                                <Link target="_blank" href={brandPageLink}>
                                    <img loading='lazy' src={brand?.brand_logo_url} className={`${iconStyle} rounded-full  object-contain `} />
                                </Link>

                                <img loading='lazy' src={country?.image} className={`${iconStyle} rounded-full `} />
                            </div>
                            <Link href={redirectLink} className={`${textSize} text-gray-900 py-0  md:py-2`}>{item_name}
                            </Link>

                        </div>

                        <div className='flex flex-col justify-center items-center space-y-1 mt-6 md:mt-0'>
                            <p className={`${textSize} whitespace-nowrap font-bold`}>₹ {list_price} </p>
                            {max_retail_price == list_price ? null : (
                                <p className={`${textSize} text-gray-500 line-through   whitespace-nowrap`}>
                                    ₹ {max_retail_price}
                                </p>
                            )}



                        </div>
                        <div
                            onClick={() => setIsClicked(true)}
                            className={`${isSwiper && 'hidden'} absolute -top-4 -right-4`}
                        >
                            <button className='bg-white rounded-full'>
                                <svg

                                    xmlns="http://www.w3.org/2000/svg" width={quickViewWidth} height={quickViewHeight} viewBox="0 0 33 33" fill="none">
                                    <g filter="url(#filter0_ii_11798_1125)">
                                        <path d="M24.6885 18.0329H18.2248V24.4966H14.993V18.0329H8.5293V14.8011H14.993V8.33741H18.2248V14.8011H24.6885M16.6089 0.257812C14.4868 0.257812 12.3856 0.675782 10.425 1.48786C8.46452 2.29993 6.68314 3.49021 5.18262 4.99073C2.15219 8.02116 0.449707 12.1313 0.449707 16.417C0.449707 20.7027 2.15219 24.8128 5.18262 27.8433C6.68314 29.3438 8.46452 30.5341 10.425 31.3461C12.3856 32.1582 14.4868 32.5762 16.6089 32.5762C20.8946 32.5762 25.0047 30.8737 28.0352 27.8433C31.0656 24.8128 32.7681 20.7027 32.7681 16.417C32.7681 14.2949 32.3501 12.1937 31.538 10.2331C30.726 8.27262 29.5357 6.49125 28.0352 4.99073C26.5346 3.49021 24.7533 2.29993 22.7927 1.48786C20.8322 0.675782 18.7309 0.257813 16.6089 0.257812Z" fill="#4282ff" />
                                    </g>
                                    <defs>
                                        <filter id="filter0_ii_11798_1125" x="-0.550293" y="-2.74219" width="36.3184" height="39.3184" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dx="3" dy="4" />
                                            <feGaussianBlur stdDeviation="3.5" />
                                            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_11798_1125" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dx="-1" dy="-3" />
                                            <feGaussianBlur stdDeviation="4.5" />
                                            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                            <feBlend mode="normal" in2="effect1_innerShadow_11798_1125" result="effect2_innerShadow_11798_1125" />
                                        </filter>
                                    </defs>
                                </svg>
                            </button>
                        </div>
                    </div>
                    {/* // icon buttom */}

                </div>
            </div>


            {
                isClicked ?
                    <QuickViewModal
                        onClose={handleClick}
                        isVisible={isClicked}
                        productId={_id}
                        variationId={variation_group_id}
                        availableForSale={inventory?.qty_available}
                        parentName={parent_category?.category_name}
                        subName={sub_category?.category_name}
                        childName={child_category?.category_name}
                    /> : null
            }

        </div>

    )
}

export default ProductCard