import React, { useCallback, useState } from 'react'
import { addToCartWish } from '@/context/cart/api'
import Loader from '../loading-spinner'
import { useRouter } from 'next/navigation'
import { useCart } from '@/context/cart/CartContext'
import { useAuth } from "../../context/AuthContext";
const BuyNow = ({ productId, availableForSale, orderQty }) => {
    const router = useRouter()
    const {isBuyNow, setIsBuyNow, } = useCart();
    const {setIsSignUpOpen, setIsSignInOpen} =useAuth()
    const [isLoading, setIsLoading] = useState(false)
    const handleBuyNow = async (id) => {
        const body = {
            product_id: id,
            order_qty: orderQty,
            buy_now: true
        };
        const res = await addToCartWish('/v1/cart/add', body).then(res => res.json())
      
        if (res.success) {
            const response = res?.response[0].pricing;
            if (response) {
                const { max_retail_price, list_price, discount } = response;
                const encodedMaxRetailPrice = encodeURIComponent(max_retail_price);
                const encodedListPrice = encodeURIComponent(list_price);
                const encodedDiscount = encodeURIComponent(discount);
                setIsLoading(false);
                setIsBuyNow(true);
                router.push(`/checkout?buynow=true&max_retail_price=${encodedMaxRetailPrice}&list_price=${encodedListPrice}&discount=${encodedDiscount}`);
            } 
        }else{
            if (res.message === "Unauthorized") {
                setIsSignInOpen(true);
              }
              if (res.registration_complete === false) {
                setIsSignUpOpen(true);
              }
        }
    }

    return (
        <button
            onClick={() => handleBuyNow(productId)}
            type='button'

         

            className={`text-sm font-sans flex-1 flex justify-center items-center ${!availableForSale ? 'bg-red-400 text-white pointer-events-none' : 'bg-[#c5d0f9]'}  hover:bg-[#3A4980] hover:text-white  py-2   rounded-full cursor-pointer `}

            disabled={!availableForSale}
        >
            Buy Now{isLoading ? <Loader className={'w-4 h-4'} /> : null}
        </button>
    )
}

export default BuyNow